import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('section', {
    staticClass: "hvdata-grid"
  }, [_c('div', {
    staticClass: "akt-head"
  }, [!_vm.isLoadingAkt ? _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('div', {
    staticClass: "wz-tabs tar"
  }, [_c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.selTab == 'general'
    },
    on: {
      "click": function click($event) {
        _vm.selTab = "general";
      }
    }
  }, [_vm._v("Allgemein")]), _c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.selTab == 'files'
    },
    on: {
      "click": function click($event) {
        _vm.selTab = "files";
      }
    }
  }, [_vm._v("Dateien")])])])]) : _vm._e()]), _c('div', {
    staticClass: "akt-content"
  }, [_vm.isLoadingAkt ? _c('div', {
    staticClass: "openakt"
  }, [!_vm.fbDataLoaded ? _c('div', {
    staticClass: "tac padding"
  }, [_c('div', {
    staticClass: "loading-spinner _large"
  })]) : _vm._e(), _vm.fbDataLoaded ? _c('div', {
    staticClass: "tac padding"
  }, [_c('p', [_vm._v("HV ID: "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.currentHvUid) + " ")]), _vm._v("existiert nicht!")]), _c('router-link', {
    staticClass: "link bold",
    attrs: {
      "to": "/allianz/hvw"
    }
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Zurück zur Übersichtsliste")])])], 1) : _vm._e()]) : !_vm.fbDataLoaded ? _c('div', {
    staticClass: "openakt"
  }, [_vm._m(0)]) : _c('div', {
    staticClass: "openakt"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selTab == 'general',
      expression: "selTab == 'general'"
    }]
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', [_vm._v("HV " + _vm._s(_vm.currentHv.name))])]), _c('div', {
    staticClass: "card-content"
  }, [_vm.isLoading ? _c('div', [_c('div', {
    staticClass: "loading-spinner"
  })]) : _c('div', [_c('p', [_c('router-link', {
    staticClass: "link bold",
    attrs: {
      "to": "/akte/" + _vm.currentHv.id
    }
  }, [_vm._v("Sammellink")])], 1), _c('p'), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Status")]), _vm.hvStatus ? _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.$_O2A(_vm.hvStatus),
      "reduce": function reduce(item) {
        return item.id;
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('span', {
          staticClass: "bold"
        }, [_vm._v(_vm._s(option.icon) + " ")]), _c('span', [_vm._v(_vm._s(option.name))])];
      }
    }], null, false, 2319901329),
    model: {
      value: _vm.currentHv.status,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHv, "status", $$v);
      },
      expression: "currentHv.status"
    }
  })], 1) : _vm._e()]), _c('br'), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Bezeichnung / Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHv.name,
      expression: "currentHv.name"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.currentHv.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHv, "name", $event.target.value);
      }
    }
  })]), _c('br'), _c('h5', [_vm._v("Kontakt")]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Email")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHv.email,
      expression: "currentHv.email"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.currentHv.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHv, "email", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Telefon")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHv.phone,
      expression: "currentHv.phone"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.currentHv.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHv, "phone", $event.target.value);
      }
    }
  })]), _c('h5', [_vm._v("Adresse")]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Straße")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: (_vm.currentHv.address || {}).street,
      expression: "(currentHv.address||{}).street"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": (_vm.currentHv.address || {}).street
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHv.address || {}, "street", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("PLZ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: (_vm.currentHv.address || {}).postal,
      expression: "(currentHv.address||{}).postal"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": (_vm.currentHv.address || {}).postal
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHv.address || {}, "postal", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Ort")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: (_vm.currentHv.address || {}).city,
      expression: "(currentHv.address||{}).city"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": (_vm.currentHv.address || {}).city
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHv.address || {}, "city", $event.target.value);
      }
    }
  })]), _c('h5', [_vm._v("Sonstiges")]), _c('div', {
    staticClass: "bold"
  }, [_vm._v("Notizen Office")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHv.generalInfo,
      expression: "currentHv.generalInfo"
    }],
    attrs: {
      "placeholder": "",
      "name": "sonstigeInfos"
    },
    domProps: {
      "value": _vm.currentHv.generalInfo
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHv, "generalInfo", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', [_vm._v("Zugewiesene Akte (" + _vm._s(_vm.assigendProjects.length) + ")")])]), _c('div', {
    staticClass: "card-content"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.assigendProjects, function (project, idx) {
    return _c('tr', [_c('td', {
      staticClass: "bold"
    }, [_c('router-link', {
      staticClass: "link",
      attrs: {
        "to": "/allianz/list/" + project.id
      }
    }, [_vm._v(_vm._s(project.allianzData.polnr))])], 1), _c('td', [_vm._v(_vm._s((_vm.aktStatus[project.status] || {}).icon) + " " + _vm._s((_vm.aktStatus[project.status] || {}).name))]), _c('td', [_c('span', [_vm._v(_vm._s(project.allianzData.adresse_risiko) + " " + _vm._s(project.allianzData.adresse_risiko_zusatz))]), _c('br'), _c('small', {
      staticClass: "bold"
    }, [_vm._v(" " + _vm._s(project.allianzData.plz_risiko) + " " + _vm._s(project.allianzData.ortschaft_risiko))])]), _c('td')]);
  }), 0)])])]), _vm._isAdmin || _vm._isSv && _vm.svRights.allianzReadWriteAll ? _c('div', [_c('AllianzAktHistory', {
    attrs: {
      "history": _vm.currentHv.__history
    }
  })], 1) : _vm._e()]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selTab == 'files',
      expression: "selTab == 'files'"
    }]
  }, [_c('DateienAllianz', {
    attrs: {
      "reload": _vm.selTab == 'files',
      "view": 'tree',
      "currentProjectUid": _vm.currentHvUid
    }
  })], 1)])]), _vm.selTab == 'general' ? _c('div', {
    class: {
      _opened: _vm.isHvChanged
    },
    attrs: {
      "id": "savebtn"
    },
    on: {
      "click": _vm.saveHausvwerwaltung
    }
  }, [_c('i', {
    staticClass: "icon-upload"
  }), _c('br'), _c('span', {
    staticClass: "bold"
  }, [_vm._v("SPEICHERN")])]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "padding"
  }, [_c('div', {
    staticClass: "loading-spinner _large"
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('tr', [_c('th', [_vm._v("Pol.Nr.")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Adresse")]), _c('th', [_vm._v("-")])])]);
}];
export { render, staticRenderFns };